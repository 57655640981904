import { toRefs, shallowReactive, computed } from 'vue';
import firebase from 'firebase';
// Required for side-effects
import 'firebase/firestore';
import router from '../router';
import { UseForgotState } from '@/interfaces/businessgame';
import useToast from '@/composables/use-toast';
import { useI18n } from 'vue-i18n';

export default function () {
  const { successToast } = useToast();
  const { t } = useI18n();

  let state: UseForgotState = shallowReactive({
    error: '',
    username: '',
    loading: false
  });

  /**
   * have this value `isValid` get updated when the dependent properties
   * are changed in the composition function
   */
  let isValid = computed(() => {
    let { username } = state;
    return username.length;
  });

  const forgot = () => {
    state.loading = true;

    firebase
      .auth()
      .sendPasswordResetEmail(state.username)
      .then(
        () => {
          successToast(t('forgot.success'));
          router.push('/login');
        },
        error => (state.error = error)
      )
      .catch(error => {
        // Handle Errors here.
        state.error = error;
      })
      .finally(() => {
        state.loading = false;
      });
  };

  return {
    // return all of the properties from the function
    ...toRefs(state),

    // return all of the computed value to make sure we
    // get non null values for username/password from the
    // function
    isValid,

    // pass back a login and logout function to be utilized
    // by the login form
    forgot
  };
}
