
  import { computed, defineComponent, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  import useRegister from '@/composables/use-register';
  import useToast from '@/composables/use-toast';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'Register',
    setup() {
      const { currentRoute } = useRouter();
      const store = useStore();
      const { t } = useI18n();

      const tokenData: any = ref(null);

      const {
        username,
        password,
        register,
        isValid,
        confirmPassword,
        error: registerStateError,
        loading
      } = useRegister();
      const { errorToast } = useToast();

      const setUserAsVerified = async () => {
        await db
          .doc(`companies/${tokenData.value.companyId}/users/${tokenData.value.inviteEmail}`)
          .update({ pending: false });
      };

      onMounted(async () => {
        if (currentRoute.value.query.token) {
          try {
            tokenData.value = await store.dispatch('decodeToken', currentRoute.value.query.token);

            username.value = tokenData.value.inviteEmail;
          } catch (e) {
            errorToast(e);
          }
        }

        if (currentRoute.value.query.companyId && currentRoute.value.query.inviteEmail) {
          tokenData.value = {
            companyId: currentRoute.value.query.companyId,
            inviteEmail: currentRoute.value.query.inviteEmail
          };

          username.value = tokenData.value.inviteEmail;
        }
      });

      const handleRegister = async () => {
        if (isValid.value) {
          try {
            if (username.value !== tokenData.value.inviteEmail) {
              errorToast(t('errors.noInvite'));
              return;
            }

            await register();
            await setUserAsVerified();
          } catch (e) {
            errorToast(e);
          }
        } else {
          errorToast(t('errors.provideEmailPasswords'));
        }
      };

      return {
        loading,
        error: computed(() => registerStateError.value),
        username,
        confirmPassword,
        password,
        handleRegister
      };
    }
  });
