
  import { computed, defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  import useLogin from '@/composables/use-login';
  import useToast from '@/composables/use-toast';

  export default defineComponent({
    name: 'Login',
    setup() {
      const { username, password, login, isValid, error: loginStateError, loading } = useLogin();
      const { errorToast } = useToast();
      const { t } = useI18n();
      const store = useStore();

      const handleLogin = async () => {
        if (isValid.value) {
          try {
            await login();
            // await store.dispatch('sendAdminEmail', {
            //   message: `Sb logged in - ${username.value}`
            // });
          } catch (e) {
            errorToast(e);
          }
        } else {
          errorToast(t('errors.provideEmailAndPassword'));
        }
      };

      return {
        loading,
        error: computed(() => loginStateError.value),
        username,
        password,
        handleLogin
      };
    }
  });
