import { toRefs, shallowReactive, computed } from 'vue';
import firebase from 'firebase';
import 'firebase/firestore';
import { UseLoginState } from '@/interfaces/businessgame';
import { useRouter } from 'vue-router';
import useToast from '@/composables/use-toast';

export default function () {
  let state: UseLoginState = shallowReactive({
    error: '',
    username: '',
    password: '',
    user: null,
    loading: false
  });
  const { push, currentRoute } = useRouter();
  const { errorToast } = useToast();

  /**
   * have this value `isValid` get updated when the dependent properties
   * are changed in the composition function
   */
  let isValid = computed(() => {
    let { username, password } = state;
    return username.length && password.length;
  });

  const getCurrentCompanyId = async () => {
    return await firebase
      .firestore()
      .collection('companies')
      .where('userEmails', 'array-contains', state.username)
      .get()
      .then((querySnapshot: any) => {
        const resultArray: any = [];

        querySnapshot.forEach((doc: any) => {
          resultArray.push(doc.id);
        });

        return resultArray[0];
      });
  };

  const checkIfUserIsAdmin = async (companyId: string) => {
    return await firebase
      .firestore()
      .doc(`companies/${companyId}/users/${state.username}`)
      .get()
      .then(doc => {
        const data = doc.data();

        return data?.roles.admin;
      });
  };

  const login = async () => {
    state.loading = true;

    await firebase
      .auth()
      .signInWithEmailAndPassword(state.username, state.password)
      .then(
        async () => {
          const companyId = await getCurrentCompanyId();
          const isAdmin = await checkIfUserIsAdmin(companyId);

          if (currentRoute.value.query.name && currentRoute.value.query.id) {
            push({
              // @ts-ignore
              name: currentRoute.value.query.name,
              // @ts-ignore
              params: { id: currentRoute.value.query.id }
            });

            return;
          }

          if (isAdmin) {
            push('/dashboard');
          } else {
            push('/wait-for-game-link');
          }
        },
        error => {
          errorToast(error.message);
          throw new Error();
        }
      )
      .catch(error => {
        // Handle Errors here.
        state.error = error;
      })
      .finally(() => {
        state.loading = false;
      });
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {},
        error => (state.error = error)
      )
      .catch(error => {
        // Handle Errors here.
        state.error = error;
      });
  };

  return {
    ...toRefs(state),
    isValid,
    login,
    logout
  };
}
