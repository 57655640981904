import { toRefs, shallowReactive, computed } from 'vue';
import firebase from 'firebase';
// Required for side-effects
import 'firebase/firestore';
import { UseRegisterState } from '@/interfaces/businessgame';
import router from '../router';
import useToast from '@/composables/use-toast';

export default function () {
  const { errorToast } = useToast();

  let state: UseRegisterState = shallowReactive({
    error: '',
    username: '',
    password: '',
    confirmPassword: '',
    loading: false
  });

  /**
   * have this value `isValid` get updated when the dependent properties
   * are changed in the composition function
   */
  let isValid = computed(() => {
    let { username, password, confirmPassword } = state;
    return username.length && password.length && password === confirmPassword;
  });

  const register = async () => {
    state.loading = true;

    await firebase
      .auth()
      .createUserWithEmailAndPassword(state.username, state.password)
      .then(
        () => {
          router.push('/login');
        },
        error => {
          console.log(error);
          // Handle Errors here.
          errorToast(error);
          state.error = error;
        }
      )
      .catch(error => {
        console.log(error);
        // Handle Errors here.
        errorToast(error);
        state.error = error;
      })
      .finally(() => {
        state.loading = false;
      });
  };

  return {
    // return all of the properties from the function
    ...toRefs(state),

    // return all of the computed value to make sure we
    // get non null values for username/password from the
    // function
    isValid,

    // pass back a login and logout function to be utilized
    // by the login form
    register
  };
}
