import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "double-view__left" }
const _hoisted_2 = { class: "double-view__left-content" }
const _hoisted_3 = { class: "mb3 color-white center text-shadow" }
const _hoisted_4 = { class: "input-wrapper" }
const _hoisted_5 = { for: "username" }
const _hoisted_6 = { class: "input-wrapper" }
const _hoisted_7 = { for: "password" }
const _hoisted_8 = { class: "double-view__additional-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('auth.logIn')), 1),
      _createVNode("div", _hoisted_4, [
        _createVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('auth.email')), 1),
        _createVNode(_component_Input, {
          modelValue: _ctx.username,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.username = $event)),
          id: "username",
          type: "text",
          onKeyup: _withKeys(_ctx.handleLogin, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('auth.password')), 1),
        _createVNode(_component_Input, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.password = $event)),
          id: "password",
          type: "password",
          onKeyup: _withKeys(_ctx.handleLogin, ["enter"])
        }, null, 8, ["modelValue", "onKeyup"])
      ]),
      _createVNode(_component_Button, {
        onClick: _ctx.handleLogin,
        class: "my2"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loading ? _ctx.$t('common.loading') : _ctx.$t('auth.login')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode("div", _hoisted_8, [
        _createVNode("p", {
          class: "mx2",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/forgot-password')))
        }, _toDisplayString(_ctx.$t('auth.forgotPassword')), 1)
      ])
    ])
  ]))
}