
  import { computed, defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';

  import useForgot from '@/composables/use-forgot';
  import useToast from '@/composables/use-toast';

  export default defineComponent({
    name: 'ForgotPassword',
    setup() {
      const { username, forgot, isValid, error: forgotStateError, loading } = useForgot();
      const { errorToast } = useToast();
      const { t } = useI18n();

      const handleForgot = () => {
        if (isValid.value) {
          try {
            forgot();
          } catch (e) {
            errorToast(e);
          }
        } else {
          errorToast(t('errors.provideEmail'));
        }
      };

      return {
        loading,
        error: computed(() => forgotStateError.value),
        username,
        handleForgot
      };
    }
  });
